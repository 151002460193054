import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-black-lp-300 font-semibold pt-4"
}
const _hoisted_2 = {
  key: 2,
  class: "text-gray-lp-600 py-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LpButton = _resolveComponent("LpButton")!

  return (_openBlock(), _createBlock("div", {
    class: ["w-full h-full justify-center items-center flex flex-col mx-auto text-14px xxl:text-14px overflow-hidden", _ctx.customClass]
  }, [
    _createVNode("img", {
      src: require(`@/app/ui/assets/svg/${_ctx.icon}.svg`),
      class: "mx-auto mt-12",
      alt: _ctx.icon
    }, null, 8, ["src", "alt"]),
    (_ctx.customHeaderMessage)
      ? (_openBlock(), _createBlock("span", _hoisted_1, _toDisplayString(_ctx.customHeaderMessage), 1))
      : _createCommentVNode("", true),
    (_ctx.customMessage)
      ? (_openBlock(), _createBlock("span", {
          key: 1,
          class: `text-gray-lp-600 ${_ctx.customHeaderMessage ? 'py-3' : 'py-2'}`
        }, _toDisplayString(_ctx.customMessage), 3))
      : (_openBlock(), _createBlock("p", _hoisted_2, _toDisplayString(_ctx.messageEmptyError), 1)),
    (_ctx.buttonAction)
      ? (_openBlock(), _createBlock(_component_LpButton, _mergeProps({
          key: 3,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('action')))
        }, _ctx.buttonAction), null, 16))
      : _createCommentVNode("", true)
  ], 2))
}