/* eslint-disable */
import {
    AddGenerateManualSTTRequestInterface
  } from "../contracts/ManualSTTRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";


export class AddGenerateManualSTTApiRequest implements AddGenerateManualSTTRequestInterface {
    private account_type: string;
    private account_ref_id: number;
    private total_stt: number;
    private is_mixpack: boolean;

    constructor(
        account_type: string,
        account_ref_id: number,
        total_stt: number,
        is_mixpack: boolean
    ) {
        this.account_type = account_type;
        this.account_ref_id = account_ref_id;
        this.total_stt = total_stt;
        this.is_mixpack = is_mixpack;
    }
    public toJSON(): string {
        return JSON.stringify({
            account_type: this.account_type,
            account_ref_id: this.account_ref_id,
            total_stt: this.total_stt,
            is_mixpack: this.is_mixpack
        });
    }
}

export class RequestListManualStt {
    page = 1;
    limit = 10;
    status = "";
    account_ref_id = "";
    account_type = "";
    isTotalData = false;
    search = "";
    version = "1";
  
    constructor(fields?: Partial<RequestListManualStt>) {
      Object.assign(this, fields);
    }
  
    toQueryString(): string {
      return new QueryParamsEntities(this).queryString;
    }
  }
