/* eslint-disable @typescript-eslint/camelcase */
import { ManualSTTEntities } from "@/domain/entities/ManualSTT";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { ManualSTTPresenter } from "../presenters/ManualSTTPresenter";
import { container } from "tsyringe";
import {
  AddGenerateManualSTTApiRequest,
  RequestListManualStt
} from "@/data/payload/api/ManualSTTApiRequest";
import { getToken } from "@/app/infrastructures/misc/Cookies";
import {
  formatDateNormal,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

export interface ManualSTTState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  ManualSTTData: ManualSTTEntities;
  isError: boolean;
  isErrorEdit: boolean;
  isErrorCause: string;
  openModal: boolean;
  openModalSuccess: boolean;
  isOpenManualSTT: boolean;
}
@Module({ namespaced: true, dynamic: true, store, name: "manual_stt" })
class ManualSTTStore extends VuexModule implements ManualSTTState {
  public isLoading = false;
  public isLoadingAdd = false;
  public isLoadingDetail = false;
  public isLoadingDownload = false;
  public ManualSTTData = new ManualSTTEntities(new Pagination(1, 20), []);
  public search = "";
  public status = "";
  public isError = false;
  public isErrorEdit = false;
  public isErrorCause = "";
  public openModal = false;
  public openModalSuccess = false;
  public firstRequest = true;
  public openSuccess = false;
  public isOpenManualSTT = false;
  public accountType = "";
  public accountRefId = "";

  @Action
  public getManualSTTList(params: RequestListManualStt) {
    this.setLoading(true);
    const presenter = container.resolve(ManualSTTPresenter);
    presenter
      .getManualSTTList(params)
      .then((res: ManualSTTEntities) => {
        this.setManualSTTData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onAdd(params: {
    accountType: string;
    accountRefId: number;
    totalStt: number;
    isMixpack: boolean;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ManualSTTPresenter);
    presenter
      .addGenerateManualSTT(
        new AddGenerateManualSTTApiRequest(
          params.accountType,
          params.accountRefId,
          params.totalStt,
          params.isMixpack
        )
      )
      .then(() => {
        this.setIsOpenManualSTT(false);
        this.fetchManualSTTList();
      })
      .catch(error => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Generate Gagal !", () =>
            this._onAdd(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public downloadFile() {
    this.setLoadingDownload(true);
    const headers = new Headers();
    const token = `Bearer ${getToken()}`;
    const baseUrl = process.env.VUE_APP_GENESIS_API_URL;
    const dateTime = new Date();
    headers.append("authorization", token);
    const url = `${baseUrl}/hydra/v1/stt_manual/download/excel?status=${this.status}&account_ref_id=${this.accountRefId}`;
    fetch(url, { headers })
      .then((response: any) => {
        return response.blob();
      })
      .then((blob: any) => {
        const newUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = newUrl;
        anchor.download = `manual_stt_${formatDateNormal(dateTime)}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(newUrl);
        this.setOpenModalSuccess(true);
        this.setLoadingDownload(false);
      })
      .catch((error: any) => {
        console.log(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  }

  @Action
  public fetchManualSTTList() {
    this.setFirstRequest(false);
    this.getManualSTTList(
      new RequestListManualStt({
        page: this.ManualSTTData.pagination.page,
        limit: this.ManualSTTData.pagination.limit,
        status: this.status,
        account_ref_id: this.accountRefId,
        account_type: this.accountType,
        search: this.search,
        isTotalData: true,
        version: "2"
      })
    );
  }

  @Action
  public initManualSTT() {
    this.setFirstPage();
    this.setFirstRequest(true);
    this.setFilterStatus("");
    this.setSearch("");
    this.getManualSTTList(
      new RequestListManualStt({
        page: this.ManualSTTData.pagination.page,
        limit: this.ManualSTTData.pagination.limit,
        status: this.status,
        account_ref_id: "",
        account_type: "",
        search: "",
        isTotalData: true,
        version: "2"
      })
    );
  }

  @Action
  public setFirstPage() {
    this.ManualSTTData.pagination.page = 1;
  }

  @Action
  public selectClientPartnerAction(params: { name: string; value: string }) {
    this.setFilterClientPartner(params.value);
    this.setFirstPage();
    this.fetchManualSTTList();
  }

  @Action
  public selectStatusAction(params: { name: string; value: string }) {
    this.setFilterStatus(params.value);
    this.setFirstPage();
    this.fetchManualSTTList();
  }

  @Action
  public selectFilterStatus(params: { value: string }) {
    this.setFilterStatus(params.value);
  }

  @Action
  public selectFilterClientPartner(params: { value: string }) {
    this.setFilterClientPartner(params.value);
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    this.setFirstPage();
    this.fetchManualSTTList();
  }

  @Action
  public clear() {
    this.searchAction("");
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setLoadingAdd(isLoading: boolean) {
    this.isLoadingAdd = isLoading;
  }

  @Mutation
  private setLoadingDownload(isLoading: boolean) {
    this.isLoadingDownload = isLoading;
  }

  @Mutation
  private setManualSTTData(data: ManualSTTEntities) {
    this.ManualSTTData = data;
  }

  @Mutation
  private setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  private setFilterStatus(value: string) {
    this.status = value;
  }

  @Mutation
  private setFilterClientPartner(value: string) {
    this.accountRefId = value;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }

  @Mutation
  public setErrorCause(str: string) {
    this.isErrorCause = str;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Action
  public handleError() {
    this.setError(false);
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  @Mutation
  public setIsOpenManualSTT(value: boolean) {
    this.isOpenManualSTT = value;
  }
}

export const ManualSTTController = getModule(ManualSTTStore);
