import { ellipsisString } from "@/app/infrastructures/misc/Utils";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const generateFormPdf = (data: any, logo: string, barcode: any[]) => {
  const doc: any = new jsPDF("p", "mm", "a4", true);

  data.forEach((item: any, index: number) => {
    if (index > 0) {
      doc.addPage("a4", "p");
    }
    doc.addImage(logo, "PNG", 9, 14, 40, 10.1, "logo", "FAST", 0);

    // title
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor("#4d4d4d");
    doc.text("Formulir Booking", 153, 17);

    // barcode
    doc.addImage(
      barcode[index].barcode,
      "PNG",
      152,
      19,
      49,
      8.2,
      `barcode-${item.id}`,
      "FAST",
      0
    );

    // date
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");

    // detail pengiriman
    doc.setDrawColor("#78787A");
    doc.setFillColor("#78787A");
    doc.roundedRect(9, 30, 191, 8, 0, 0, "FD");
    doc.setFontSize(8);
    doc.setTextColor("#FFFFFF");
    doc.text("D E T A I L  P E N G I R I M A N", 93, 35);

    // Tanggal
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Tanggal", 9, 47);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(44, 42, 35.5, 7, 1, 1, "FD");

    // stt manual
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("STT Manual", 9, 57);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(44, 52, 57, 7, 1, 1, "FD");
    doc.text(`${item.id}`, 47, 56.5);

    // kecamatan kota asal
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Kecamatan, Kota Asal", 9, 67);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(44, 62, 57, 7, 1, 1, "FD");

    // nama pengirim
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Nama Pengirim", 9, 77);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(44, 72, 57, 7, 1, 1, "FD");

    // alamat pengirim
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Alamat Pengirim", 9, 87);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(44, 82, 57, 16, 1, 1, "FD");

    // no telp pengirim
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("No. Telepon", 9, 106);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(44, 101, 57, 7, 1, 1, "FD");

    // produk pengiriman
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Produk Pengiriman", 9, 116);
    doc.setTextColor("#E0E0E0");
    doc.text("(Checklist satu produk)", 9, 119);

    // nama client/pos
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Nama Client/POS", 110, 47);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(143, 42, 57, 7, 1, 1, "FD");
    doc.setFontSize(7);
    doc.text(ellipsisString(item.sttManualName, 30), 146, 46.5);

    // no reff external
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("No. Referensi Eksternal", 110, 56);
    doc.setTextColor("#E0E0E0");
    doc.text("(Opsional)", 110, 59);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(143, 52, 57, 7, 1, 1, "FD");

    // kecamatan kota tujuan
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Kecamatan, Kota Tujuan", 110, 67);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(143, 62, 57, 7, 1, 1, "FD");

    // nama penerima
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Nama Penerima", 110, 77);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(143, 72, 57, 7, 1, 1, "FD");

    // alamat penerima
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Alamat Penerima", 110, 87);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(143, 82, 57, 16, 1, 1, "FD");

    // no telp penerima
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("No. Telepon", 110, 106);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(143, 101, 57, 7, 1, 1, "FD");

    // onepack
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(44, 111, 6, 6, 1, 1, "FD");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("ONEPACK", 52, 115);

    // regpack
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(44, 118, 6, 6, 1, 1, "FD");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("REGPACK", 52, 122);

    // docupack
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(75, 111, 6, 6, 1, 1, "FD");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("DOCUPACK", 83, 115);

    // JAGOPACK
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(75, 118, 6, 6, 1, 1, "FD");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("JAGOPACK", 83, 122);

    // BIGPACK
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(110, 111, 6, 6, 1, 1, "FD");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("BIGPACK", 118, 115);

    // interpack
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(110, 118, 6, 6, 1, 1, "FD");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("INTERPACK", 118, 122);

    // detail barang
    doc.setDrawColor("#78787A");
    doc.setFillColor("#78787A");
    doc.roundedRect(9, 130, 191, 8, 0, 0, "FD");
    doc.setTextColor("#FFFFFF");
    doc.text("D E T A I L  B A R A N G", 93, 135);

    // deskripsi barang
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Deskripsi Barang", 9, 147);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(44, 142, 57, 7, 1, 1, "FD");

    // detail barang
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Detail Barang", 9, 157);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(44, 152, 57, 7, 1, 1, "FD");

    // estimasi harga barang
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Estimasi Harga Barang", 110, 147);
    doc.setDrawColor("#F5F6F7");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(152, 142, 36, 7, 1, 1, "FD");
    doc.setFillColor("#F5F6F7");
    doc.roundedRect(143, 142, 10, 7, 1, 1, "FD");
    // help close
    doc.setDrawColor("#F5F6F7");
    doc.setFillColor("#F5F6F7");
    doc.roundedRect(149, 142, 4, 7, 0, 0, "FD");
    doc.setTextColor("#4d4d4d");
    doc.text("IDR", 145.5, 146.5);

    // woodpacking
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Woodpacking", 110, 157);
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(143, 152, 7, 7, 1, 1, "FD");
    doc.text("Packing Kayu", 152, 157);

    // asuransi
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Asuransi", 9, 165);
    doc.setTextColor("#E0E0E0");
    doc.text("(Checklist satu produk)", 9, 168);

    // insurance free
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(44, 162, 6, 6, 1, 1, "FD");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Free", 52, 166);

    // insurance basic
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(62, 162, 6, 6, 1, 1, "FD");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Basic", 70, 166);

    // insurance premium
    doc.setDrawColor("#E0E0E0");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(80, 162, 6, 6, 1, 1, "FD");
    doc.setFontSize(8);
    doc.setTextColor("#4d4d4d");
    doc.text("Premium", 88, 166);

    doc.autoTable({
      head: [
        [
          "Koli",
          "Gross Weight (Kg)",
          "Dimensi (P x L x T)",
          "Volume Weight (Kg)"
        ]
      ],
      body: [
        ["1", "", "", ""],
        ["2", "", "", ""],
        ["3", "", "", ""],
        ["4", "", "", ""],
        ["5", "", "", ""],
        ["6", "", "", ""],
        ["7", "", "", ""],
        ["8", "", "", ""],
        ["9", "", "", ""],
        ["10", "", "", ""],
        ["11", "", "", ""],
        ["12", "", "", ""],
        ["13", "", "", ""],
        ["14", "", "", ""],
        ["15", "", "", ""]
      ],
      theme: "grid",
      margin: { top: 173, left: 9, right: 10, bottom: 7 },
      headStyles: {
        font: "helvetica",
        fontStyle: "bold",
        fillColor: "#78787A",
        textColor: "#FFFFFF",
        fontSize: 8,
        halign: "left",
        valign: "top",
        cellPadding: { top: 2, right: 2, bottom: 2, left: 2 }
      },
      bodyStyles: {
        fontSize: 6,
        cellPadding: { top: 2, right: 2, bottom: 2, left: 2 }
      }
    });

    // footer
    doc.setDrawColor("#78787A");
    doc.setFillColor("#78787A");
    doc.roundedRect(9, 280, 191, 12, 0, 0, "FD");

    // footer gross weight
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(9);
    doc.setFont("helvetica", "bold");
    doc.text("Total Gross Weight", 12, 286.7);

    doc.setDrawColor("#F5F6F7");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(44, 282.5, 36, 7, 1, 1, "FD");
    doc.setFillColor("#F5F6F7");
    doc.roundedRect(73, 282.5, 10, 7, 1, 1, "FD");
    // help close
    doc.setDrawColor("#F5F6F7");
    doc.setFillColor("#F5F6F7");
    doc.roundedRect(73, 282.5, 4, 7, 0, 0, "FD");
    doc.setTextColor("#4d4d4d");
    doc.setFont("helvetica", "normal");
    doc.text("Kg", 76, 287);

    // footer volume weight
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(9);
    doc.setFont("helvetica", "bold");
    doc.text("Total Volume Weight", 100, 286.7);

    doc.setDrawColor("#F5F6F7");
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(134, 282.5, 36, 7, 1, 1, "FD");
    doc.setFillColor("#F5F6F7");
    doc.roundedRect(160, 282.5, 10, 7, 1, 1, "FD");
    // help close
    doc.setDrawColor("#F5F6F7");
    doc.setFillColor("#F5F6F7");
    doc.roundedRect(160, 282.5, 4, 7, 0, 0, "FD");
    doc.setTextColor("#4d4d4d");
    doc.setFont("helvetica", "normal");
    doc.text("Kg", 163, 287);
  });

  doc.setProperties({
    title: `form-booking-manual.pdf`
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
};

export default generateFormPdf;
