import { jsPDF } from "jspdf";
import {
  externalRefSection,
  abbreviationVendorCode,
  titleCase,
  destinationRect
} from "../module";
import { formatPrice, uuid } from "@/app/infrastructures/misc/Utils";
import { PrintDataEntitas } from "@/domain/entities/Booking";

const generateResiA6 = async (
  accountType: string,
  data: PrintDataEntitas[],
  logo: string
) => {
  const paper = "a6";
  const addX = [0, 105, 0, 105];
  const addY = [0, 0, 147, 147];
  let position = 0;
  const doc: any = new jsPDF("p", "mm", paper, true);

  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;
  for (const item of data) {
    if (position > 0) {
      position = 0;
      doc.addPage(paper, "p");
    }

    const externalRefValue = externalRefSection(item.sttNoRefExternal)
      .externalRef;
    const pajak =
      Number(item.sttBmTaxRate) +
      Number(item.sttPpnTaxRate) +
      Number(item.sttPphTaxRate);
    const ursaCode = `${abbreviationVendorCode(
      item.sttVendorName,
      item.sttDistrictType
    )}${item.sttDistrictUrsaCode}`;
    doc.addImage(
      logo,
      "PNG",
      addX[position] + 5,
      addY[position] + 5,
      32,
      logoAspectRatio * 32,
      "logo",
      "FAST",
      0
    );

    // LINE TOP
    doc.setLineDash();
    doc.setLineWidth(0.3);
    doc.line(
      addX[position] + 0,
      addY[position] + 14,
      addX[position] + 105,
      addY[position] + 14
    );

    // VERTICAL LINE
    doc.setLineWidth(0.5);
    doc.line(
      addX[position] + destinationRect(ursaCode).verticalLineLeft,
      addY[position] + 7,
      addX[position] + destinationRect(ursaCode).verticalLineLeft,
      addY[position] + 12
    );

    // URSA
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(13);
    doc.text(ursaCode, addX[position] + 100, addY[position] + 9, {
      align: "right"
    });

    // GENESIS BARCODE
    doc.addImage(
      item.barcode,
      "PNG",
      addX[position] + 3,
      addY[position] + 18,
      68,
      15,
      uuid(),
      "FAST",
      0
    );
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(8);
    doc.text(
      item.sttElexysNo || item.sttNo,
      addX[position] + 5,
      addY[position] + 18
    );
    doc.text(
      item.sttElexysNo || item.sttNo,
      addX[position] + 5.1,
      addY[position] + 18
    );

    // DESTINATION DISTRICT
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(8);
    doc.text(
      item.sttDestinationDistrictName ??
        titleCase(item.sttDestinationDistrictName),
      addX[position] + 100,
      addY[position] + 18,
      {
        align: "right"
      }
    );

    // CREATED DATE
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(6);
    doc.text(
      `Dibuat :\n\n\nEstimasi :\n`,
      addX[position] + 100,
      addY[position] + 21,
      {
        align: "right"
      }
    );

    // SHIPER CONSIGNE
    doc.setLineWidth(0.3);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(addX[position] + 5, addY[position] + 34, 95, 31, 1, 1);

    // SHIPER LABEL
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(6);
    doc.text("Pengirim", addX[position] + 6, addY[position] + 38);
    doc.text(":", addX[position] + 18, addY[position] + 38);
    doc.setFont("helvetica", "bold");

    // CONSIGNE LABEL
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(6);
    doc.text("Penerima", addX[position] + 6, addY[position] + 43);
    doc.text(":", addX[position] + 18, addY[position] + 43);
    doc.setFont("helvetica", "bold");

    // REFF
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(6);
    doc.text(
      `Ex. Ref : ${externalRefValue ?? "-"}`,
      addX[position] + 80,
      addY[position] + 82,
      {
        align: "right"
      }
    );

    // COMPANY
    const elipsisCompanyName =
      item.sttBookedName.length >= 45
        ? `${item.sttBookedName.substring(0, 45)}...`
        : item.sttBookedName;
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(6);
    doc.text(elipsisCompanyName, addX[position] + 5, addY[position] + 82);

    // LINE MIDDLE
    doc.setLineWidth(0.3);
    doc.line(
      addX[position] + 0,
      addY[position] + 84,
      addX[position] + 105,
      addY[position] + 84
    );

    // PRICE LEFT
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(6);
    doc.text("Total Biaya", addX[position] + 5, addY[position] + 100);
    doc.text(":", addX[position] + 17, addY[position] + 100);
    doc.setFont("helvetica", "bold");

    doc.setFont("helvetica", "normal");
    doc.text("Biaya Kirim", addX[position] + 5, addY[position] + 104);
    doc.text(":", addX[position] + 17, addY[position] + 104);

    doc.text("Asuransi", addX[position] + 5, addY[position] + 108);
    doc.text(":", addX[position] + 17, addY[position] + 108);

    // PRICE RIGHT
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(6);
    doc.text("Total Berat", addX[position] + 35, addY[position] + 100);
    doc.text(":", addX[position] + 47, addY[position] + 100);
    doc.setFont("helvetica", "bold");
    doc.text(`      Kg`, addX[position] + 48, addY[position] + 100);

    doc.setFont("helvetica", "normal");
    doc.text("Pajak/BM", addX[position] + 35, addY[position] + 104);
    doc.text(":", addX[position] + 47, addY[position] + 104);
    doc.text(
      `${Number(pajak) > 0 ? formatPrice(pajak) : ""}`,
      addX[position] + 48,
      addY[position] + 104
    );

    doc.text("Lain-lain", addX[position] + 35, addY[position] + 108);
    doc.text(":", addX[position] + 47, addY[position] + 108);

    // GW
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text("GW", addX[position] + 59, addY[position] + 101);
    doc.text(":", addX[position] + 66, addY[position] + 101);

    // VW
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text("VW", addX[position] + 59, addY[position] + 109);
    doc.text(":", addX[position] + 66, addY[position] + 109);

    // SUGGEST DOWNLOAD TOP
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(4.5);
    doc.text(
      "Download Aplikasi Lion Parcel melalui Appstore atau Playstore dan lacak paketmu sekarang!",
      addX[position] + 5,
      addY[position] + 112
    );

    // LINK LP TOP
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(4.5);
    doc.text(
      "S&K : lionparcel.com/sk",
      addX[position] + 100,
      addY[position] + 112,
      {
        align: "right"
      }
    );

    // TEXT CUT
    const cut = await import("@/app/ui/assets/images/black-cut.png");
    doc.setFont("helvetica", "normal");
    doc.addImage(
      cut.default,
      "PNG",
      addX[position] + 5,
      addY[position] + 114,
      2.5,
      2.5,
      "cut",
      "FAST"
    );
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(4.5);
    doc.text("Lembar Pengirim", addX[position] + 9, addY[position] + 116);

    // LOGO BOTTOM
    doc.addImage(
      logo,
      "PNG",
      addX[position] + 5,
      addY[position] + 120,
      13,
      logoAspectRatio * 13,
      "logo",
      "FAST",
      0
    );

    // GENESIS BARCODE BOTTOM
    doc.addImage(
      item.barcode,
      "PNG",
      addX[position] + 19,
      addY[position] + 117,
      38,
      8,
      uuid(),
      "FAST",
      0
    );
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(6);
    doc.text(
      item.sttElexysNo || item.sttNo,
      addX[position] + 28,
      addY[position] + 127
    );

    // LABEL CONSIGNE
    doc.setLineWidth(0.3);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(addX[position] + 5, addY[position] + 117, 95, 28, 1, 1);

    // SHIPER LABEL BOTTOM
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(5);
    doc.text("Pengirim", addX[position] + 6, addY[position] + 130);
    doc.text(":", addX[position] + 16, addY[position] + 130);
    doc.setFont("helvetica", "bold");

    // CONSIGNE LABEL BOTTOM
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(5);
    doc.text("Penerima", addX[position] + 6, addY[position] + 134);
    doc.text(":", addX[position] + 16, addY[position] + 134);
    doc.setFont("helvetica", "bold");

    // DESTINATION LABEL BOTTOM
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(5);
    doc.text("Kota Tujuan", addX[position] + 6, addY[position] + 138);
    doc.text(":", addX[position] + 16, addY[position] + 138);
    doc.setFont("helvetica", "bold");
    doc.text(
      item.sttDestinationDistrictName ??
        titleCase(item.sttDestinationDistrictName),
      addX[position] + 17,
      addY[position] + 138
    );

    // ESTIMASI BOTTOM
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(5);
    doc.text("Estimasi", addX[position] + 6, addY[position] + 141);
    doc.text(":", addX[position] + 16, addY[position] + 141);
    doc.setFont("helvetica", "bold");

    // SUGGEST DOWNLOAD TOP
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(3.5);
    doc.text(
      "Download Aplikasi Lion Parcel melalui Appstore atau Playstore dan lacak paketmu sekarang!",
      addX[position] + 6,
      addY[position] + 144
    );

    // LINK LP BOTTOM
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(3.5);
    doc.text(
      "S&K : lionparcel.com/sk",
      addX[position] + 82,
      addY[position] + 144,
      {
        align: "right"
      }
    );

    // VERTICAL LINE BOTTOM 1
    doc.setLineWidth(0.3);
    doc.line(
      addX[position] + 83,
      addY[position] + 145,
      addX[position] + 83,
      addY[position] + 117
    );

    // VERTICAL LINE BOTTOM 2
    doc.setLineWidth(0.3);
    doc.line(
      addX[position] + 57,
      addY[position] + 141,
      addX[position] + 57,
      addY[position] + 117
    );

    // PRICE BOTTOM
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(5);
    doc.text("Total Berat", addX[position] + 58, addY[position] + 130.5);
    doc.text(":", addX[position] + 69, addY[position] + 130.5);
    doc.setFont("helvetica", "bold");
    doc.text(`         Kg`, addX[position] + 70, addY[position] + 130.5);

    doc.setFont("helvetica", "normal");
    doc.text("Biaya Kirim", addX[position] + 58, addY[position] + 132.5);
    doc.text(":", addX[position] + 69, addY[position] + 132.5);

    doc.setFont("helvetica", "normal");
    doc.text("Asuransi", addX[position] + 58, addY[position] + 134.5);
    doc.text(":", addX[position] + 69, addY[position] + 134.5);

    doc.setFont("helvetica", "normal");
    doc.text("Pajak/BM", addX[position] + 58, addY[position] + 136.5);
    doc.text(":", addX[position] + 69, addY[position] + 136.5);
    doc.setFont("helvetica", "bold");
    doc.text(
      `${Number(pajak) > 0 ? formatPrice(pajak) : ""}`,
      addX[position] + 70,
      addY[position] + 136.5
    );

    doc.setFont("helvetica", "normal");
    doc.text("Lain-Lain", addX[position] + 58, addY[position] + 138.5);
    doc.text(":", addX[position] + 69, addY[position] + 138.5);
    doc.setFont("helvetica", "bold");

    // TOTAL BAYAR BOTTOM
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(6);
    doc.text("Total Biaya", addX[position] + 86, addY[position] + 138);
    doc.setFont("helvetica", "bold");

    // LINE HORIZONTAL BOTTOM 1
    doc.setLineWidth(0.3);
    doc.line(
      addX[position] + 5,
      addY[position] + 128,
      addX[position] + 57,
      addY[position] + 128
    );

    // LINE HORIZONTAL BOTTOM 2
    doc.setLineWidth(0.3);
    doc.line(
      addX[position] + 83,
      addY[position] + 134,
      addX[position] + 100,
      addY[position] + 134
    );

    // LINE BOTTOM
    doc.setLineDash([1]);
    doc.line(
      addX[position] + 0,
      addY[position] + 114,
      addX[position] + 104,
      addY[position] + 114
    );

    position++;
  }

  doc.setProperties({
    title: `resi-manual-stt.pdf`
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
};

export default generateResiA6;
