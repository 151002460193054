
import { PropType } from "vue";
import { Vue, Options, prop } from "vue-class-component";
import Checkbox from "primevue/checkbox";
import { ClientController } from "@/app/ui/controllers/ClientController";
import debounce from "lodash/debounce";
import { ManualSTTController } from "@/app/ui/controllers/ManualSTTController";
import {
  ClientMappingList,
  ClientPartnerData,
  RequestApiClientMappingList
} from "@/domain/entities/Client";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";

class Props {
  onClose = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
}

@Options({
  name: "UploadForm",
  components: {
    Checkbox
  }
})
export default class extends Vue.with(Props) {
  mounted() {
    this.getClientMapping("");
  }

  // State Value
  checked = false;
  typeName = "";
  typeSTT = [
    { name: "Pilih Jenis STT", value: "" },
    { name: "Client", value: "client" },
    { name: "Partner", value: "partner" }
  ];

  amountSTT = "";

  typeSelect = false;

  onOpenTypeSelect() {
    this.typeSelect = true;
  }

  onCloseTypeSelect() {
    this.typeSelect = false;
  }

  isTypeSttSelected = true;
  onSelectType(name: string, value: string) {
    this.clientValue = "";
    this.typeName = value;
    this.onCloseTypeSelect();
    this.checked = false;

    this.isTypeSttSelected = !value ? true : false;
    this.fetchClientPartner("");
  }

  //  Is disabled
  get isDisabled() {
    return (
      !this.typeName ||
      !Number(this.amountSTT) ||
      !this.clientValue ||
      Number(this.amountSTT) > Number(this.maxGenerateManualSTT)
    );
  }

  _onAdd() {
    if (!this.isDisabled) {
      const clientData: any = this.clientValue;
      ManualSTTController._onAdd({
        accountType: this.typeName,
        accountRefId: Number(clientData.id),
        totalStt: Number(this.amountSTT),
        isMixpack: this.checked
      });
    }
  }

  get isLoading() {
    return ManualSTTController.isLoadingAdd;
  }

  // client/partner
  get getAccount(): any {
    const dataAccount = {
      accountType: AccountController.accountData.account_type,
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : 0,
      accountName:
        AccountController.accountData.account_type_detail.company_name,
      accountCode: AccountController.accountData.account_type_detail.code
    };
    if (
      dataAccount.accountType != "internal" &&
      dataAccount.accountType != ""
    ) {
      this.typeName = dataAccount.accountType;
      this.clientValue = this.isClientParent
        ? ""
        : new ClientPartnerData(
            dataAccount.accountClientId,
            dataAccount.accountName,
            dataAccount.accountCode,
            dataAccount.accountType,
            ""
          );
    }
    return dataAccount;
  }
  clientValue: any = "";
  fetchClientPartner = debounce((search: string) => {
    ClientController._onGetListPartner({
      page: 1,
      limit: 10,
      search: search,
      partnerType: this.typeName === "partner" ? "pos" : "",
      type: this.typeName,
      isBranch: true
    });
  }, 250);

  onSearchClientName = debounce((value: string) => {
    if (!value || value.length >= 3) {
      this.clientValue = value;
      if (this.isClientParent) {
        ClientController._onGetCLientMappingList(
          new RequestApiClientMappingList({
            page: 1,
            limit: 10,
            search: value,
            isBranch: this.isClientParent
          })
        );
      }
    }
  }, 250);

  getClientMapping(search: string) {
    if (this.isClientParent) {
      ClientController._onGetCLientMappingList(
        new RequestApiClientMappingList({
          page: 1,
          limit: 10,
          search: search,
          isBranch: this.isClientParent
        })
      );
    }
  }

  get isClient() {
    return this.dataProfile.account_type === "client";
  }

  get isClientParent() {
    return this.isClient && !this.dataProfile.account_type_detail.parentId;
  }
  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  get clientPartnerData() {
    return ClientController.clientPartnerData;
  }

  get mappedDataClient() {
    return ClientController.clientMappingData.map(
      (client: ClientMappingList) => ({
        id: client.clientId,
        name: client.clientName,
        code: client.clientCode
      })
    );
  }

  get isLoadingClientPartner() {
    return ClientController.isLoading;
  }

  get maxGenerateManualSTT() {
    return FlagsMedusa.config_max_generate_manual_stt.getValue();
  }
}
