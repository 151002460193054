import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_GenerateSTT = _resolveComponent("GenerateSTT")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Header, {
        currentRoutes: _ctx.getCurrentRoute,
        isSearchable: true,
        placeholder: "Cari No. STT genesis",
        clearSearch: _ctx.clearSearch,
        searchValue: _ctx.searchValue,
        searchAction: _ctx.onSearch,
        isSelectSearchSingle: _ctx.isFilterClient,
        placeholderSelectSearchSingle: _ctx.placeHolderSearchClientPartner,
        searchValueSelectSearchSingle: _ctx.selectSearchClientPartnerValue,
        selectSearchSingleData: _ctx.searchClientPartnerList,
        onSelectSearchSingle: _ctx.onSelectSearchClientPartner,
        onOpenSelectSearchSingle: _ctx.onOpenSelectSearchClientPartner,
        onCloseSelectSearchSingle: _ctx.onCloseSelectSearchClientPartner,
        onChooseSelectSearchSingle: _ctx.onChooseSelectSearchClientPartner,
        statusSelectSearchSingle: _ctx.statusSelectSearchClientPartner,
        isSelectRenewal: true,
        statusDataRenewal: _ctx.statusDataRenewal,
        statusSelectRenewal: _ctx.statusSelectRenewal,
        onOpenStatusSelectRenewal: _ctx.onOpenStatusSelectRenewal,
        onCloseStatusSelectRenewal: _ctx.onCloseStatusSelectRenewal,
        onSelectStatusRenewal: _ctx.onSelectStatusRenewal,
        valueStatusRenewal: _ctx.statusNameRenewal,
        isButtonAction: _ctx.isCreateAble,
        buttonText: `Generate STT`,
        buttonAction: _ctx.onOpenManualSTT,
        class: "z-20 w-2"
      }, null, 8, ["currentRoutes", "clearSearch", "searchValue", "searchAction", "isSelectSearchSingle", "placeholderSelectSearchSingle", "searchValueSelectSearchSingle", "selectSearchSingleData", "onSelectSearchSingle", "onOpenSelectSearchSingle", "onCloseSelectSearchSingle", "onChooseSelectSearchSingle", "statusSelectSearchSingle", "statusDataRenewal", "statusSelectRenewal", "onOpenStatusSelectRenewal", "onCloseStatusSelectRenewal", "onSelectStatusRenewal", "valueStatusRenewal", "isButtonAction", "buttonAction"]),
      (_ctx.isError && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ErrorMessage, {
            key: 0,
            errorType: _ctx.errorCause,
            onTryAgain: _ctx.onRequest
          }, null, 8, ["errorType", "onTryAgain"]))
        : (_ctx.ManualSTT.length === 0 && _ctx.firstRequest && !_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_EmptyState, {
              key: 1,
              data: "data Manual STT"
            }))
          : (_openBlock(), _createBlock(_component_TableV2, {
              key: 2,
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
              loading: _ctx.isLoading,
              columns: _ctx.columns,
              data: _ctx.ManualSTT,
              class: "my-2",
              onRequest: _ctx.onRequest,
              isCheckBox: _ctx.isDownloadAble,
              filterCheckBox: _ctx.filterCheckBox,
              checkedData: _ctx.checkedData,
              "onUpdate:checkedData": _cache[2] || (_cache[2] = ($event: any) => (_ctx.checkedData = $event)),
              onDownloadResi: _cache[3] || (_cache[3] = e => _ctx.downloadCheckbox(e, 'resi')),
              onDownloadForm: _cache[4] || (_cache[4] = e => _ctx.downloadCheckbox(e, 'form')),
              paginationStyle: _ctx.paginationStyle
            }, null, 8, ["pagination", "loading", "columns", "data", "onRequest", "isCheckBox", "filterCheckBox", "checkedData", "paginationStyle"]))
    ]),
    (_ctx.isOpenManualSTT)
      ? (_openBlock(), _createBlock(_component_GenerateSTT, {
          key: 0,
          onClose: _ctx.onCloseManualSTT
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (_ctx.openConfirmationLeave)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 1,
          onSubmit: _ctx.handlerClose,
          onClose: _ctx.handlerCancelClose,
          title: "Yakin Keluar Halaman ?",
          message: "Anda belum selesai mengisi form. Permintaan tidak akan diproses jika Anda keluar dari halaman ini.",
          image: "are-you-sure",
          textSuccess: "Keluar",
          textCancel: "Kembali"
        }, null, 8, ["onSubmit", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}